// // import React from 'react';
// // import Slider from 'react-slick';
// // // import 'slick-carousel/slick/slick.css';
// // // import 'slick-carousel/slick/slick-theme.css';
// // // import 'react-slick/lib/';
// // import img1 from '../../images/img-1.jpg';
// // import img2 from '../../images/img-2.jpg';
// // import img3 from '../../images/img-3.jpg';
// // import img4 from '../../images/img-4.jpg';
// // import Carousel from 'react-multi-carousel';
// // import 'react-multi-carousel/lib/styles.css';
// // import CardsMain from '../cards/CardsMain';

// // export default function CardsCarousel() {
// //   const responsive = {
// //     desktop: {
// //       breakpoint: { max: 3000, min: 1024 },
// //       items: 3,
// //       slidesToSlide: 3, // optional, default to 1.
// //     },
// //     tablet: {
// //       breakpoint: { max: 1024, min: 464 },
// //       items: 2,
// //       slidesToSlide: 2, // optional, default to 1.
// //     },
// //     mobile: {
// //       breakpoint: { max: 464, min: 0 },
// //       items: 1,
// //       slidesToSlide: 1, // optional, default to 1.
// //     },
// //   };

// //   return (
// //     <Carousel
// //       swipeable={false}
// //       draggable={false}
// //       showDots={true}
// //       responsive={responsive}
// //       ssr={true} // means to render carousel on server-side.
// //       infinite={true}
// //       autoPlay={this.props.deviceType !== 'mobile' ? true : false}
// //       autoPlaySpeed={1000}
// //       keyBoardControl={true}
// //       customTransition="all .5"
// //       transitionDuration={500}
// //       containerClass="carousel-container"
// //       removeArrowOnDeviceType={['tablet', 'mobile']}
// //       deviceType={this.props.deviceType}
// //       dotListClass="custom-dot-list-style"
// //       itemClass="carousel-item-padding-40-px"
// //     >
// //       <CardsMain></CardsMain>
// //     </Carousel>
// //   );
// // }

// import React, { Component } from 'react';
// import ReactCardCarousel from 'react-card-carousel';

// class CardsCarousel extends Component {
//   constructor(props) {
//     super(props);

//     this.aspectRatio = '4x3';
//     this.cards = this.renderCards(
//       12,
//       this.aspectRatio,
//       Math.round(1200 * (4 / 3))
//     );
//     this.handlePrevNext = this.handlePrevNext.bind(this);

//     this.state = {
//       index: 0,
//     };
//   }

//   // Centers the controls within the image using the method from AspectRatioCard
//   getControlOffset(aspectRatio) {
//     return (width, controlHeight) => {
//       return AspectRatioCard.getControlOffset(
//         width,
//         controlHeight,
//         aspectRatio
//       );
//     };
//   }

//   // Handles previous and next click to adjust the index.
//   handlePrevNext(evt, newIndex) {
//     this.setState({ index: newIndex });
//   }

//   // Utility method to randomly generate card content for the carousel.
//   renderCards(length, aspectRatio, imgWidth) {
//     let cards = [];
//     for (let i = 1; i <= length; i++) {
//       const useLong = !!Math.round(Math.random());
//       let title = !useLong
//         ? `Card Title ${i}`
//         : `Card Title ${i} that is really long and needs truncation`;
//       let text = !useLong
//         ? `Card Text ${i}`
//         : `Card Text ${i} that is really long and needs truncation. It just keeps going and going and never ends. When will this test description end? Who knows?`;

//       cards.push(
//         <AspectRatioCard aspectRatio={aspectRatio} key={`card-${i}`}>
//           <AspectRatioCardImage
//             data-slot={'figure'}
//             imageUri={`https://placeimg.com/${imgWidth}/1000/arch/${i}`}
//           />
//           <AspectRatioCardTitle data-slot={'caption'} title={title} />
//           <AspectRatioCardText data-slot={'content'} text={text} />
//         </AspectRatioCard>
//       );
//     }
//     return cards;
//   }

//   render() {
//     return (
//       <CardCarousel
//         breakpoints={{
//           820: 4,
//           540: 3,
//           0: 2,
//         }}
//         cardIndex={this.state.index}
//         getControlOffset={this.getControlOffset(this.aspectRatio)}
//         onNextClick={this.handlePrevNext}
//         onPreviousClick={this.handlePrevNext}
//         nextLabel={'Next'}
//         paging
//         previousLabel={'Previous'}
//         title={'Sample Carousel'}
//       >
//         {this.cards}
//       </CardCarousel>
//     );
//   }
// }

// export default CardsCarousel;

/************************************
1. If you want to add or remove items you will need to change a variable called $slide-count in the CSS *minimum 3 slides

2. if you want to change the dimensions of the slides you will need to edit the slideWidth variable here 👇 and the $slide-width variable in the CSS.
************************************/
import React from 'react';
import '../styles/ccstyle.css';
import { useEffect } from 'react';
import { useState } from 'react';
import img1 from '../assets/cardcaro/cardcaro1.webp';
import img2 from '../assets/cardcaro/cardcaro2.jpeg';
import img3 from '../assets/cardcaro/cardcaro3.jpeg';
import img4 from '../assets/cardcaro/cardcaro4.jpeg';
import img5 from '../assets/cardcaro/cardcaro5.jpeg';
import img6 from '../assets/cardcaro/cardcaro6.jpeg';

const slideWidth = 30;

const _items = [
  {
    player: {
      title: 'Efren Reyes',
      desc: 'Known as "The Magician", Efren Reyes is well regarded by many professionals as the greatest all around player of all time.',
      image: img1,
    },
  },
  {
    player: {
      title: "Ronnie O'Sullivan",
      desc: "Ronald Antonio O'Sullivan is a six-time world champion and is the most successful player in the history of snooker.",
      image: img4,
    },
  },
  {
    player: {
      title: 'Shane Van Boening',
      desc: 'The "South Dakota Kid" is hearing-impaired and uses a hearing aid, but it has not limited his ability.',
      image: img3,
    },
  },
  {
    player: {
      title: 'Mike Sigel',
      desc: 'Mike Sigel or "Captain Hook" as many like to call him is an American professional pool player with over 108 tournament wins.',
      image: img5,
    },
  },
  {
    player: {
      title: "Ronnie O'Sullivan",
      desc: "Ronald Antonio O'Sullivan is a six-time world champion and is the most successful player in the history of snooker.",
      image: img6,
    },
  },
];

const length = _items.length;
_items.push(..._items);

const sleep = (ms = 0) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const createItem = (position, idx) => {
  const item = {
    styles: {
      transform: `translateX(${position * slideWidth}rem)`,
    },
    player: _items[idx].player,
  };

  switch (position) {
    case length - 1:
    case length + 1:
      item.styles = { ...item.styles, filter: 'grayscale(1)' };
      break;
    case length:
      break;
    default:
      item.styles = { ...item.styles, opacity: 0 };
      break;
  }

  return item;
};

const CarouselSlideItem = ({ pos, idx, activeIdx }) => {
  const item = createItem(pos, idx, activeIdx);

  return (
    <li className="carousel__slide-item" style={item.styles}>
      <div className="carousel__slide-item-img-link">
        <img src={item.player.image} alt={item.player.title} />
      </div>
      <div className="carousel-slide-item__body">
        <h4>{item.player.title}</h4>
        <p>{item.player.desc}</p>
      </div>
    </li>
  );
};

const keys = Array.from(Array(_items.length).keys());

export default function CardsCarousel() {
  const [items, setItems] = React.useState(keys);
  const [isTicking, setIsTicking] = React.useState(false);
  const [activeIdx, setActiveIdx] = React.useState(0);
  const bigLength = items.length;

  // autoplay stuff
  const [current, setCurrent] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  let timeOut = null;
  useEffect(() => {
    // timeOut =
    //   autoPlay &&
    setTimeout(() => {
      nextClick();
    }, 4000);
  });

  // const slideRight = () => {
  //   console.log('================');
  //   console.log(_items.length);
  //   console.log(current);
  //   console.log('================');
  //   setCurrent(current === _items.length - 1 ? 0 : current + 1);
  // };

  const prevClick = (jump = 1) => {
    if (!isTicking) {
      setIsTicking(true);
      setItems((prev) => {
        return prev.map((_, i) => prev[(i + jump) % bigLength]);
      });
    }
  };

  const nextClick = (jump = 1) => {
    if (!isTicking) {
      setIsTicking(true);
      setItems((prev) => {
        return prev.map((_, i) => prev[(i - jump + bigLength) % bigLength]);
      });
    }
  };

  const handleDotClick = (idx) => {
    if (idx < activeIdx) prevClick(activeIdx - idx);
    if (idx > activeIdx) nextClick(idx - activeIdx);
  };

  React.useEffect(() => {
    if (isTicking) sleep(300).then(() => setIsTicking(false));
  }, [isTicking]);

  React.useEffect(() => {
    setActiveIdx((length - (items[0] % length)) % length) // prettier-ignore
  }, [items]);

  return (
    <div className="carousel__wrap">
      <div className="carousel__inner">
        <button
          className="carousel__btn carousel__btn--prev"
          onClick={() => prevClick()}
        >
          <i className="carousel__btn-arrow carousel__btn-arrow--left" />
        </button>
        <div
          className="carousel__container"
          onMouseEnter={() => {
            setAutoPlay(false);
            clearTimeout(timeOut);
          }}
          onMouseLeave={() => {
            setAutoPlay(true);
          }}
        >
          <ul className="carousel__slide-list">
            {items.map((pos, i) => (
              <CarouselSlideItem
                key={i}
                idx={i}
                pos={pos}
                activeIdx={activeIdx}
              />
            ))}
          </ul>
        </div>
        <button
          className="carousel__btn carousel__btn--next"
          onClick={() => nextClick()}
        >
          <i className="carousel__btn-arrow carousel__btn-arrow--right" />
        </button>
        <div className="carousel__dots">
          {items.slice(0, length).map((pos, i) => (
            <button
              key={i}
              onClick={() => handleDotClick(i)}
              className={i === activeIdx ? 'dot active' : 'dot'}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
