import './App.css';
// import Navbar from './components/Navbar';
import NavbarCol from './components/NavbarCol';
import FooterContainer from './components/FooterContainer';

import Home from './pages/Home';
import Menu from './pages/Menu';
import About from './pages/About';
import Contact from './pages/Contact';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Router>
        <NavbarCol />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/products" exact component={Menu} />
          <Route path="/about" exact component={About} />
          <Route path="/contact" exact component={Contact} />
        </Switch>

        <FooterContainer />
      </Router>
    </div>
  );
}

export default App;
