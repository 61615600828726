// import React from 'react';
// import { Icon } from '@iconify/react';
// import yelp from '@iconify/icons-mdi/yelp';
// import insta from '@iconify/icons-mdi/instagram';

// import '../styles/Footer.css';
// import '../styles/Contact.css';

// function Footer() {
//   let getYear = () => {
//     let currentYear = new Date().getFullYear();
//     return currentYear;
//   };
//   return (
//     <div className="footer">
//       <div className="socialMedia">
//         <span className="spanText">
//           Find Us At:
//           <a
//             onClick={() =>
//               window.open('https://www.instagram.com/ksindiangrocery/?hl=en')
//             }
//           >
//             <Icon icon={insta} className="info-icon" />
//           </a>
//           <a
//             onClick={() =>
//               window.open('https://www.yelp.com/biz/ks-indian-grocery-hercules')
//             }
//           >
//             <Icon icon={yelp} className="info-icon" />
//           </a>
//         </span>
//       </div>
//       <p>
//         Copyright &copy;
//         <span> {getYear()} KS Indian Grocery - All Rights Reserved</span>
//       </p>
//     </div>
//   );
// }

// export default Footer;

// import React from 'react';
// import Footer from './components_footer/footer';
// import Icon from './components_footer/icons';

// export default function FooterContainer() {
//   return (
//     <Footer.Wrapper>
//       <Footer.Row>
//         © Copyright 2022 Opal Collection All Rights Reserved.
//       </Footer.Row>
//       <Footer.Row>
//         © Copyright 2022 Opal Collection All Rights Reserved.
//       </Footer.Row>
//     </Footer.Wrapper>
//   );
// }

import { Icon } from '@iconify/react';
import yelp from '@iconify/icons-mdi/yelp';
import insta from '@iconify/icons-mdi/instagram';
import mobile from '@iconify/icons-mdi/phone';
import '../styles/Footer.css';
import '../styles/Contact.css';
import 'bootstrap/dist/css/bootstrap.min.css';
const footerStyle = {
  backgroundColor: '#49424f',
  fontSize: '14px',
  color: 'white',
  borderTop: '1px solid #E7E7E7',
  textAlign: 'center',
  padding: '3px',
  position: 'fixed',
  left: '0',
  bottom: '0',
  height: '60px',
  width: '100%',
};

const phantomStyle = {
  display: 'block',
  padding: '3px',
  height: '60px',
  width: '100%',
};

function FooterContainer() {
  let getYear = () => {
    let currentYear = new Date().getFullYear();
    return currentYear;
  };
  return (
    <div>
      <div style={phantomStyle} />
      <div style={footerStyle}>
        <span>
          © Copyright {getYear()} KS Indian Grocery - All Rights Reserved
        </span>
        {'      '}|
        <a
          onClick={() =>
            window.open('https://www.instagram.com/ksindiangrocery/?hl=en')
          }
        >
          <Icon icon={insta} className="info-icon" />
        </a>
        <a
          onClick={() =>
            window.open('https://www.yelp.com/biz/ks-indian-grocery-hercules')
          }
        >
          <Icon icon={yelp} className="info-icon"></Icon>
        </a>
        <br></br>
      </div>
    </div>
  );
}

export default FooterContainer;
