import React from 'react';

import '../styles/Contact.css';

function ContactIntro() {
  return (
    <div className="intro-container">
      <h2 className="intro-h2">Our Mission</h2>

      <p className="intro-p">
        At KS Indian Grocery, our mission is to provide our customers with the
        freshest, highest quality products at affordable prices. We are
        dedicated to delivering exceptional customer service and creating a
        welcoming shopping experience for every visitor. We strive to support
        local farmers and suppliers, reduce our environmental impact, and give
        back to the communities we serve. Our goal is to be the preferred
        grocery destination, trusted for our integrity, reliability, and
        commitment to excellence.
      </p>
    </div>
  );
}

export default ContactIntro;
