import React from 'react';
import '../styles/Contact.css';

import ContactIntro from '../components/ContactIntro';
import ContactSection from '../components/ContactSection';

function Contact() {
  return (
    <div>
      <ContactIntro></ContactIntro>
      <ContactSection></ContactSection>
    </div>
  );
}

export default Contact;
