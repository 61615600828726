import React from 'react';
import { Link } from 'react-router-dom';
import BannerImage from '../assets/home1.jpeg';
import '../styles/Home.css';
import Carousel from '../components/Carousel';
import CardsCarousel from '../components/CardsCarousel';

function Home() {
  return (
    <div style={{ justifyContent: 'center' }}>
      <div className="home" style={{ backgroundImage: `url(${BannerImage})` }}>
        <div className="headerContainer">
          <h1> KS Indian Grocery </h1>
          <p> Check everything on your grocery list </p>
          {/* <Link to="/menu">
          <button> Know About Our Products </button>
        </Link> */}
        </div>
      </div>
      <Carousel></Carousel>
      <h1 className="apph1">Our Trending Items</h1>
      <CardsCarousel></CardsCarousel>
    </div>
  );
}

export default Home;
