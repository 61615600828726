import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStoreOutlined';
import '../styles/Navbar.css';
function NavbarCol() {
  return (
    <Navbar collapseOnSelect expand="lg" style={{ backgroundColor: '#d6d2da' }}>
      <Container>
        <Navbar.Brand href="/" className="header">
          <LocalGroceryStoreIcon></LocalGroceryStoreIcon>
          KSG
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="navitemsset">
            <Nav.Link href="/" className="navlink">
              Home
            </Nav.Link>
            <Nav.Link href="/products" className="navlink">
              Categories
            </Nav.Link>
            <Nav.Link href="/about" className="navlink">
              About
            </Nav.Link>
            <Nav.Link href="/contact" className="navlink">
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarCol;
