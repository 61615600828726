import React from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

import '../styles/Contact.css';
const containerStyle = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
};

const center = {
  lat: 38.010941,
  lng: -122.269669,
};

function MapSection() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBe1oMgVj7_OaN2eFZpA9E51HFzGve_N6g',
  });

  const [map, setMap] = React.useState(null);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <div className="googlemap">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
        onUnmount={onUnmount}
      >
        <Marker position={center}></Marker>
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
}

export default MapSection;
