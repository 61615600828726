import React from 'react';
import MapSection from './MapSection';
import Contactinfo from './ContactInfo';

import '../styles/Contact.css';

function ContactSection() {
  return (
    <div className="contact-section">
      <MapSection />
      <Contactinfo />
    </div>
  );
}

export default ContactSection;
