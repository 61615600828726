// import React from 'react';
// import { MenuList } from '../helpers/MenuList';
// import MenuItem from '../components/MenuItem';
// import '../styles/Menu.css';

// function Menu() {
//   return (
//     <div className="menu">
//       <h1 className="menuTitle">Our Product Categories</h1>
//       <div className="menuList">
//         {MenuList.map((menuItem, key) => {
//           return (
//             <MenuItem
//               key={key}
//               image={menuItem.image}
//               name={menuItem.name}
//               price={menuItem.price}
//             />
//           );
//         })}
//       </div>
//     </div>
//   );
// }

// export default Menu;

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, CardHeader, ListGroup, ListGroupItem } from 'react-bootstrap';
import '../styles/Menu.css';

function MenuItem() {
  return (
    <div className="menu">
      <h1 className="menuTitle">Our Product Categories</h1>
      <div className="menuList">
        <Card
          style={{
            width: '18rem',
            marginBottom: '15px',
          }}
        >
          <CardHeader>Produce</CardHeader>
          <ListGroup flush>
            <ListGroupItem variant="dark">Onion</ListGroupItem>
            <ListGroupItem variant="secondary">Potato</ListGroupItem>
            <ListGroupItem variant="dark">Ginger</ListGroupItem>
            <ListGroupItem variant="secondary">Garlic</ListGroupItem>
            <ListGroupItem variant="dark">Okra</ListGroupItem>
            <ListGroupItem variant="secondary">Tender Coconut</ListGroupItem>
            <ListGroupItem variant="dark">Bitter Gourd</ListGroupItem>
            <ListGroupItem variant="secondary">Colocasia (Arbi)</ListGroupItem>
            <ListGroupItem variant="dark">Cauliflower (Ful Gobi)</ListGroupItem>
            <ListGroupItem variant="secondary">Cucumber</ListGroupItem>
            <ListGroupItem variant="dark">Capsicum Green</ListGroupItem>
          </ListGroup>
        </Card>
        <Card
          style={{
            width: '18rem',
            marginBottom: '15px',
          }}
        >
          <CardHeader>Spices</CardHeader>
          <ListGroup flush>
            <ListGroupItem variant="dark">Jeera</ListGroupItem>
            <ListGroupItem variant="secondary">Black Pepper</ListGroupItem>
            <ListGroupItem variant="dark">Dhaniya Powder</ListGroupItem>
            <ListGroupItem variant="secondary">
              Kashmiri Chilli Powder
            </ListGroupItem>
            <ListGroupItem variant="dark">Black Pepper</ListGroupItem>
            <ListGroupItem variant="secondary">Dhaniya Powder</ListGroupItem>
            <ListGroupItem variant="dark">Garam Masala</ListGroupItem>
            <ListGroupItem variant="secondary">Hing Powder</ListGroupItem>
            <ListGroupItem variant="dark">Turmeric Powder</ListGroupItem>
          </ListGroup>
        </Card>
        <Card
          style={{
            width: '18rem',
            marginBottom: '15px',
          }}
        >
          <CardHeader>Pulses & Lentils</CardHeader>
          <ListGroup flush>
            <ListGroupItem variant="dark">Toor Dal</ListGroupItem>
            <ListGroupItem variant="secondary">Channa Dal</ListGroupItem>
            <ListGroupItem variant="dark">Moong Dal</ListGroupItem>
            <ListGroupItem variant="secondary">Masoor Dal</ListGroupItem>
            <ListGroupItem variant="dark">Urad Dal</ListGroupItem>
            <ListGroupItem variant="secondary">Kabuli Channa</ListGroupItem>
            <ListGroupItem variant="dark">Kidney Beans</ListGroupItem>
          </ListGroup>
        </Card>

        <Card
          style={{
            width: '18rem',
            marginBottom: '15px',
          }}
        >
          <CardHeader>Rice & Flours</CardHeader>
          <ListGroup flush>
            <ListGroupItem variant="dark">
              Ashirwaad Multigrain Atta
            </ListGroupItem>
            <ListGroupItem variant="secondary">Sujata Gold Atta</ListGroupItem>
            <ListGroupItem variant="dark">Sher Atta</ListGroupItem>
            <ListGroupItem variant="secondary">Basmati Rice</ListGroupItem>
          </ListGroup>
        </Card>
        <Card
          style={{
            width: '18rem',
            marginBottom: '15px',
          }}
        >
          <CardHeader>Dairy</CardHeader>
          <ListGroup flush>
            <ListGroupItem variant="dark">Amul Butter</ListGroupItem>
            <ListGroupItem variant="secondary">Amul Fresh Paneer</ListGroupItem>
            <ListGroupItem variant="dark">Amul Toned Milk</ListGroupItem>
            <ListGroupItem variant="secondary">Amul Fresh Cream</ListGroupItem>
          </ListGroup>
        </Card>
      </div>
    </div>
  );
}

export default MenuItem;
