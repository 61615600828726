import React from 'react';
import { Icon, InlineIcon } from '@iconify/react';

import locationIcon from '@iconify/icons-mdi/map-marker-radius-outline';
import phoneIcon from '@iconify/icons-mdi/phone-outline';
import emailIcon from '@iconify/icons-mdi/email-multiple-outline';

import facebookIcon from '@iconify/icons-mdi/facebook';
import yelp from '@iconify/icons-mdi/yelp';
import insta from '@iconify/icons-mdi/instagram';

import '../styles/Contact.css';

const contactDetails = [
  {
    value: '1511 Sycamore Ave Ste J Hercules, CA 94547',
    icon: locationIcon,
  },
  { value: '+1 (510) 327-4083', icon: phoneIcon },
  { value: 'jsarab40@gmail.com', icon: emailIcon },
];

const socialMedia = [
  {
    icon: insta,
    href: 'https://www.instagram.com/ksindiangrocery/?hl=en',
  },
  {
    icon: yelp,
    href: 'https://www.yelp.com/biz/ks-indian-grocery-hercules',
  },
];

const renderContactDetails = () =>
  contactDetails.map((detail) => (
    <p key={detail.value} className="info-detail">
      <InlineIcon icon={detail.icon} /> {detail.value}
    </p>
  ));

const renderIcons = () =>
  socialMedia.map((detail) => (
    <a onClick={() => window.open(detail.href)}>
      <Icon icon={detail.icon} key={detail.key} className="info-icon" />
    </a>
  ));

function ContactIntro() {
  return (
    <section className="info">
      <h2 className="info-h2">Contact information</h2>

      <div className="info-details-container">{renderContactDetails()}</div>

      <div className="info-icons-container">{renderIcons()}</div>
    </section>
  );
}

export default ContactIntro;
