import React from 'react';
import spicesImg from '../assets/aboutUs.jpeg';
import '../styles/About.css';
function About() {
  return (
    <div className="about">
      <div
        className="aboutTop"
        style={{ backgroundImage: `url(${spicesImg})` }}
      ></div>
      <div className="aboutBottom">
        <h1> ABOUT US</h1>
        <p>
          Welcome to KS Indian Grocery, your one-stop destination for authentic
          Indian groceries and culinary essentials. Nestled in the heart of
          Hercules, CA. we are proud to be a beacon of tradition and taste,
          serving the diverse Indian community and enthusiasts alike. <br></br>{' '}
          At KS Indian Grocery, we believe in preserving the rich heritage of
          Indian cuisine by offering a wide array of high-quality ingredients
          sourced directly from trusted suppliers. Whether you're searching for
          spices that tantalize the senses, aromatic rice varieties, or
          specialty flours for your homemade bread, we've got you covered. Our
          shelves are stocked with everything from lentils and legumes to ghee,
          masalas, and exotic teas - ensuring that you can recreate the flavors
          of India right in your kitchen. <br></br>
          Beyond our commitment to quality, we strive to create a warm and
          welcoming environment for our customers. Our knowledgeable staff is
          always on hand to provide guidance, share recipes, and offer
          suggestions to enhance your culinary adventures. We understand that
          food is not just about sustenance; it's a cultural experience that
          brings people together. That's why we take pride in fostering a sense
          of community within our store, where customers can connect, exchange
          stories, and celebrate their love for Indian cuisine. <br></br>
          At KS Indian Grocery, our mission is simple: to be your trusted
          partner in exploring the vibrant tapestry of Indian flavors. Whether
          you're a seasoned chef or a novice cook, we invite you to embark on a
          journey of discovery with us. Come experience the essence of India at
          KS Indian Grocery, where every ingredient tells a story and every dish
          is a celebration of taste. <br></br>
          Thank you for choosing KS Indian Grocery. We look forward to serving
          you!
        </p>
      </div>
    </div>
  );
}

export default About;
